import { makeStyles } from "@material-ui/core/styles";
import { color, font, qvru, fontSize } from "./themes";

export const useStyles = makeStyles((theme) => ({
  /*////////////////////////////////////////////
  Boiler Plate
  ////////////////////////////////////////////*/
  "& .MuiContainer-root": {
    padding: "0",
  },
  island: {
    backgroundColor: `${color.white}`,
    padding: "0 !important",
    boxShadow: "0 0 10px #2c3867",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  wrapper: {
    height: "100%",
    width: "100%",
  },
  columns: {
    padding: `0 ${qvru.o2}`,
  },
  flexRow: {
    margin: `0 -${qvru.o2}`,
  },
  textSpace: {
    lineHeight: "1.5",
  },
  title: {
    display: "block",
  },
  inputRoot: {
    color: "inherit",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",

    "& .MuiButtonBase-root": {
      color: "#25225e",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  marginBottom1: {
    marginBottom: `${qvru.o2}`,
  },
  marginBottom2: {
    marginBottom: `${qvru.o4}`,
  },
  marginBottom3: {
    marginBottom: `${qvru.o6}`,
  },
  marginBottom4: {
    marginBottom: `${qvru.o8}`,
  },
  marginVertical1: {
    margin: `${qvru.o2} 0`,
  },
  marginVertical2: {
    margin: `${qvru.o4} 0`,
  },
  marginVertical3: {
    margin: `${qvru.o6} 0`,
  },
  marginVertical4: {
    margin: `${qvru.o3} 0`,
  },
  hTitleMargin: {
    marginTop: "0",
    marginBottom: `${qvru.o1}`,
    color: `${color.white}`,
  },
  fullWidth: {
    width: "100%",
  },
  //Intended to be used in free space or intro section of a component
  basicText: {
    fontSize: `${fontSize.ms0}`,
    lineHeight: `${qvru.o4}`,
  },

  //Meant to be used inside grid column
  columnText: {
    fontSize: `${fontSize.ms0}`,
    lineHeight: `${qvru.o3}`,
  },
  textPrimary: {
    color: `${color.white}`,
    padding: theme.spacing(2),
  },
  textSecondary: {
    color: `${color.white}`,
    padding: "20px 0 0 0",
    textAlign: "left",
    fontSize: `${fontSize.ms2}`,
    lineHeight: "29px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignItem: {
    alignItems: "center !important",
  },
  error: {
    alignItems: "center",
    position: "absolute",
    right: "20px",
    height: "40px",
    lineHeight: "40px",
    color: "#E44D42",
    fontSize: `${fontSize.msN2}`,
    fontWeight: "bold",
  },
  share: {
    textAlign: "center",
  },
  shareInner: {
    display: "inline-flex",
  },
  shareButton: {
    margin: `0 ${qvru.o1}`,
    transition: "opacity 0.4s ease 0s",

    "&:hover": {
      opacity: 0.4,
    },
  },
  shareWrap: {
    padding: `${qvru.o4} 0`,
  },
  shareTitle: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: `${qvru.o2}`,
    marginBottom: "20px",
  },
  center: {
    textAlign: "center",
  },
  h1Override: {
    fontSize: `${fontSize.ms6} !important`,
    fontWeight: "bold",
    color: `${color.white}`,
  },
  h2Override: {
    fontSize: `${fontSize.ms5}`,
    textAlign: "center",
    fontFamily: `${font.sectionHeading}`,
  },
  icons: {
    width: "70px",
    height: "70px",
  },
  secondaryLinkColor: {
    color: `${color.white}`,
    border: `1px solid ${color.white}`,
    textDecoration: "none",
    lineHeight: `${qvru.o4}`,
    padding: `0 ${qvru.o2}`,
    display: "inline-block",
    borderRadius: "20px",
    fontWeight: "bold",
    transition: "all 0.4s ease 0s",

    "&:hover": {
      borderColor: "#007da9",
      backgroundColor: "#007da9",
    },
  },

  /*////////////////////////////////////////////
Components
////////////////////////////////////////////*/

  //Hero

  hero: {
    width: "92%",
    maxWidth: "1280px",
    height: "703px",
    margin: "54px auto 0",
    textAlign: "center",
    alignItems: "center",
    position: "relative",
    marginBottom: "54px",

    '&$heroMarketing': {
      height: '450px',
    },

    '&$heroMarketing $heroImg': {
      height: '450px'
    },
  },
  heroMarketing: {
    position: 'relative',
  },
  heroInner: {
    position: "relative",
    height: "100%",
    maxWidth: "1160px",
    margin: "0 auto",
    display: "block",
    padding: "0 18px",

    [theme.breakpoints.up("md")]: {
      fontSize: "20.25px",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: "36px",
      display: "grid",
    },
  },
  heroContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",
    zIndex: 3,
    height: "100%",
    gridColumn: "2/-2",
  },
  heroRibbonBanner: {
    width: "216px",
    height: "214px",
    backgroundImage:
      "url(https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-ribbon-banner.png)",
    backgroundSize: "214px",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "-8px",
    left: "-10px",
    zIndex: 4,

    [theme.breakpoints.up("md")]: {
      width: "250px",
      height: "248px",
      backgroundSize: "248px",
    },

  },
  heroImg: {
    position: "absolute",
    width: "100%",
    height: "703px",
    overflow: "hidden",
    left: "0",
    top: "0",

    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,12,25,0.3) 30%, #25225e)",
    },
  },
  heroContentInner: {
    width: "100%",
  },
  heroTitle: {
    marginBottom: "36px",

    "& h1": {
      color: "#ffffff",
    },
  },
  heroMeta: {
    textTransform: "uppercase",
    fontSize: "14.22px",
    lineHeight: "27px", //Leave for now
    position: "relative",
    display: "inline-block",
    marginBottom: `${qvru.o2}`,

    "& .MuiTypography-body1": {
      color: "#ffffff",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "20.25px",
    },

    "&:before": {
      height: "3px",
      width: "36px",
      backgroundColor: "#ffffff",
      content: '""',
      position: "absolute",
      left: "-54px",
      top: "50%",
      opacity: "30%",
      marginTop: "-1.5px",
    },
    "&:after": {
      height: "3px",
      width: "36px",
      backgroundColor: "#ffffff",
      content: '""',
      position: "absolute",
      right: "-54px",
      top: "50%",
      opacity: "30%",
      marginTop: "-1.5px",
    },
  },
  heroSubTitle: {
    marginBottom: `${qvru.o6}`,

    "& .MuiTypography-body1": {
      color: "#ffffff",
      fontSize: "22.7px",
    },
  },
  heroCtas: {
    backgroundImage:
      "linear-gradient(to right, rgba(225,248,255,0.14), rgba(191,239,255,0.14))",
    display: "inline-block",

    [theme.breakpoints.up("lg")]: {
      height: "72px",
    },

    "& ul": {
      padding: "0",
      margin: "0",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",

      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        display: "inline-flex",
      },
    },
    "& li": {
      listStyle: "none",
      position: "relative",

      '&:last-child': {
        width: '100%'
      },

      [theme.breakpoints.up("md")]: {
        '&:last-child': {
          width: 'auto',
        },
      },

      [theme.breakpoints.up("lg")]: {
        height: "72px",
        lineHeight: "72px",

        "&:before": {
          content: '""',
          width: "4px",
          height: "4px",
          background: "#F9EDBF",
          position: "absolute",
          borderRadius: "100%",
          bottom: "0",
          left: "50%",
          marginLeft: "-2px",

          [theme.breakpoints.up("lg")]: {
            left: "0",
            top: "50%",
            marginTop: "-2px",
          },
        },

      },


      "&:first-child:before": {
        display: "none",
      },
      "&:last-child:before": {
        display: "none",
      },
    },
    "& a": {
      display: "block",
      padding: "18px",
      color: "#ffffff",
      fontWeight: "600",
      textDecoration: "none",

      [theme.breakpoints.up("lg")]: {
        padding: "0 36px",
      },

      "&:hover": {
        color: "#C1EFFF",
      },

      "&$primaryCta": {
        color: "#0072BC",

        "&:hover": {
          color: "#0072BC",
        },
      },
    },
  },
  primaryCta: {
    backgroundImage: "linear-gradient(to bottom right, #FAEEC1, #F2D88D)",
    color: "#0072BC",

    "&:hover": {
      backgroundImage: "linear-gradient(to bottom right, #E1F8FF, #BFEFFF)",
    },
  },
  secondaryCta: {
    backgroundImage: "linear-gradient(to bottom right, #E1F8FF, #BFEFFF)",
    color: "#0072BC",

    "&:hover": {
      backgroundImage: "linear-gradient(to bottom right, #FAEEC1, #F2D88D)",
    },
  },
  angle1: {
    backgroundImage:
      "linear-gradient(to left, rgba(173,201,212,1), rgba(255,242,46,0.14), rgba(77,131,206,0.56))",
    height: "584px",
    width: "140%",
    position: "absolute",
    bottom: "-36px",
    clipPath: "polygon(0 0,0% 0px,100% 100%,0 94%)",
  },
  angle2: {
    opacity: "15.27%",
    backgroundImage:
      "linear-gradient(to left, rgba(0,174,239,1), rgba(249,235,187,1), rgba(55,250,249,0.42))",
    height: "455px",
    width: "140%",
    position: "absolute",
    bottom: "-36px",
    clipPath: "polygon( -20% 100%, 100% 0%,100% 100%, 100% 90%)",
  },
  angle3: {
    opacity: "15.27%",
    backgroundImage: "linear-gradient(to left, #98BAC0, #0095FE)",
    height: "534px",
    width: "140%",
    position: "absolute",
    bottom: "0",
    clipPath: "polygon( -13% 100%, 100% -30%,100% 100%, 100% 100%)",
  },
  //Card
  card: {
    marginBottom: `${qvru.o4}`,
    position: "relative",

    '&.MuiGrid-item': {
      marginBottom: `${qvru.o4}`,
    }
  },
  cardInnerNoShadow: {
    boxShadow: "0 none",
  },
  cardInner: {
    position: "relative",
    backgroundColor: `${color.white}`,
    height: "100%",
    boxShadow: "0px 9px 36px rgba(0,0,0,0.06)",
    margin: "0 18px",

    "&$cardInnerNoShadow": {
      boxShadow: "none",
    },
  },

  cardLabel: {
    backgroundImage: "linear-gradient(to bottom right, #FAEEC1, #F2D88D)",
    padding: "0 18px",
    height: "27px",
    color: "#25225e",
    display: "inline-block",
    transform: "translateX(-50%) translateY(-50%)",
    position: "relative",
    left: "50%",
    margin: "0 auto",

    "& .MuiTypography-body1": {
      fontSize: "14px",
      lineHeight: "27px",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "0.44 px",
    },
  },
  cardTitle: {
    margin: `${qvru.o1} 0`,
    padding: "0 18px",
    textAlign: "center",

    '& .MuiTypography-h5': {
      lineHeight: '27px',
    },
  },
  cardImg: {
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  cardInfo: {
    padding: "0 18px 18px",

    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  cardText: {
    color: "#2D2E30",
    letterSpacing: "0",
    marginBottom: `${qvru.o2}`,
  },
  cardColumns: {
    marginBottom: `${qvru.o2}`,
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
  },
  cardCta: {
    backgroundColor: `${color.white}`,
    color: `${color.black60}`,
    height: `${qvru.o4}`,
    display: "inline-block",
    border: "1px solid #1e1e1e",
    borderRadius: "20px",
    padding: "0 15px",
    textTransform: "uppercase",
    textAlign: "center",
    letterSpacing: "0.1",
    transition: "all 0.4s ease 0s",

    "& a": {
      color: `${color.black60}`,
      display: "block",

      "&:hover": {
        color: `${color.white}`,
      },
    },

    "& svg": {
      fontSize: `${fontSize.ms2}`, //Leave for now
      position: "relative",
      top: "5px",
      marginRight: "3.5px",
    },
    "&:hover": {
      color: `${color.white}`,
      backgroundColor: `${color.black90}`,
    },
  },
  //Footer
  footerText: {
    color: `${color.lightGrey}`,
  },
  footer: {
    padding: `${qvru.o4} 0`,
    marginTop: "auto",
    backgroundColor: "#4a4870",
  },
  footerSplit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerColumn1: {
    width: "100%",
    order: 3,
    borderTop: `1px solid ${color.black60}`,
    paddingTop: `${qvru.o2}`,
    marginTop: `${qvru.o2}`,

    [theme.breakpoints.up("md")]: {
      order: 1,
      flex: "1",
      borderTop: "0 none",
      paddingTop: "0",
      marginTop: "0",
    },
  },
  footerColumn2: {
    marginBottom: `${qvru.o2}`,
    order: 1,

    [theme.breakpoints.up("md")]: {
      order: 2,
      marginBottom: "0",
    },
  },
  footerColumn3: {
    order: 2,

    [theme.breakpoints.up("md")]: {
      order: 3,
      flex: "1",
    },
  },
  footerCopyright: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#ffffff",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerCopyrightImg: {
    width: "50px",
    marginRight: `${qvru.o1}`,

    "& img": {
      width: "100%",
      display: "block",
    },
  },
  footerPolicyLinks: {
    "& a": {
      marginLeft: `${qvru.o2}`,
      color: `${color.white}`,
      textTransform: "uppercase",
      fontWeight: "600",

      "&:first-child": {
        marginLeft: "0",
      },
    },
  },
  // MarketPlace
  marketPlaceRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  marketPlaceText: {
    width: "100%",
    marginBottom: `${qvru.o3}`,
  },
  marketPlaceLogoSoup: {
    margin: "0 auto 9px",
    padding: `0 ${qvru.o2}`,
  },
  marketPlaceLogoSoupInner: {
    display: "flex",
    flexDirection: "column",

    "& img": {
      width: "100%",
    },

    "& $marketPlaceLogos": {
      width: "100%",

      "&:first-child": {
        paddingLeft: "0",
      },
      "&:last-child": {
        paddingRight: "0",

        [theme.breakpoints.up("md")]: {
          position: "relative",
          top: "-9.5px", //Consider having MARCOM create two graphics that align side-by-side
        },
      },
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  marketPlaceLogos: {
    padding: "0 4.5px",
  },
  marketPlaceTitleImgWrap: {
    display: "flex",
  },
  marketPlaceTitleImg: {
    height: "44px",
    width: `${qvru.o6}`,
    backgroundImage:
      "url(https://content-believe-in-music.s3.us-west-2.amazonaws.com/marketplace_2_7c9d700e1b.png)",
    backgroundSize: "127px",
    marginRight: "10px",
    transition: "background 0.2s ease 0s",
  },
  marketPlaceTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: `${qvru.o2}`,
  },
  marketHover: {
    position: "relative",

    "&:hover": {
      "& $marketPlaceTitleImg": {
        backgroundPosition: "-64px 0",
      },
    },
  },
  marketPlaceTitleH: {
    marginTop: "0",
    marginBottom: "0",
    color: `${color.white}`,
  },
  marketPlaceTitleImgPosition: {
    height: `${qvru.o4}`,
    marginTop: `${qvru.o1}`,
  },
  marketPlaceBody: {
    lineHeight: `${qvru.o4}`,
    marginBottom: `${qvru.o3}`,
  },
  marketPlaceUl: {
    paddingLeft: `${qvru.o2}`,
    lineHeight: `${qvru.o4}`,
  },
  // Form
  formInputWrap: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
  },
  formRow: {
    position: "relative",

    "& label.MuiFormControlLabel-root": {
      width: "33.3333% !important",
    },
  },
  formItem: {
    "& .MuiFormLabel-root": {
      marginBottom: "18px",
    },
  },
  formTextField: {
    width: "100%",
    marginBottom: `${qvru.o3} !important`,
  },
  formLabel: {
    color: `${color.grayBlue}`,
    textTransform: "capitalize",
    display: "block",
  },
  formLabelSpan: {
    color: "#768b92",
  },
  formRequired: {
    marginBottom: "0",
    fontSize: "16px !important",
  },
  formText: {
    color: "#003864",
    marginBottom: `${qvru.o2}`,
  },
  formError: {
    color: "#ca0000",
    position: "absolute",
    bottom: `${qvru.o1}`,
    fontSize: "12px",
    textTransform: "uppercase",
  },
  selectWrap: {
    width: "100%",
    position: "relative",
    paddingBottom: "10px",

    "&:hover": {
      selectArrow: {
        backgroundPosition: "0 -27px",
      },
    },
  },
  selectArrow: {
    height: `${qvru.o2}`,
    width: `${qvru.o2}`,
    backgroundImage:
      "url(https://content-believe-in-music.s3.us-west-2.amazonaws.com/select_arrow_f96fab5e5e.png)",
  },
  countryDropdownWrapper: {
    position: "relative",
  },
  formSelect: {
    height: `${qvru.o4}`,
    padding: `0 ${qvru.o2}`,
    lineHeight: `${qvru.o4}`, //Leave for now
    width: "100%",
    marginBottom: `${qvru.o2}`,
  },
  formCheckbox: {
    color: "#222",
  },
  stepHeight: {
    minHeight: "660px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "0",
  },
  stepHeightInner: {
    width: "100%",
  },
  stepTitle: {
    width: "100%",
    textAlign: "center",
    fontSize: "24px", //Leave for now
    lineHeight: `${qvru.o5}`, // Leave for now
    borderBottom: `1px solid ${color.silver}`,
    backgroundColor: "#f3f3f3",
    marginBottom: `${qvru.o1}`,
    textTransform: "uppercase",
    color: `${color.black60}`,

    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: `${qvru.o6}`,
    },
  },
  // Promo Tile

  promoTileWrap: {
    display: "flex",
    flexDirection: "column",
    margin: "36px -9px 0",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "0",
      padding: `0 ${qvru.o1}`,
    },
  },

  promoTile: {
    marginBottom: `${qvru.o2}`,

    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },

    [theme.breakpoints.up("md")]: {
      width: "33.3333%",
    },
  },
  promoTileInner: {
    position: "relative",
    margin: "0 9px",
    boxShadow: "0 0 25px #131313",

    "&:hover $promoTileContent": {
      background: "#2C3A8A",
    },
  },

  promoTileContent: {
    position: "absolute",
    width: "100%",
    padding: `${qvru.o1} ${qvru.o2}`,
    textAlign: "center",
    backgroundColor: `${color.black50}`,
    bottom: "0",
    transition: "background 0.4s ease 0s",
  },
  promoTileImg: {
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  promoTileTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    color: `${color.white}`,
  },
  promoTileTime: {
    position: "relative",
    top: "-4.5px",
    fontSize: fontSize.msN1,
  },
  promoTileSection: {
    background: `${color.black60}`,
    marginBottom: `${qvru.o8}`,

    "& .MuiPaginationItem-root": {
      color: `${color.white}`,
    },

    "& .MuiPagination-ul": {
      display: "inline-flex",
      left: "50%",
      position: "relative",
      transform: "translateX(-50%)",
    },
  },
  //Exhibitor List
  exhibitorListItem: {
    display: "flex",
    borderBottom: `1px solid ${color.black70}`,
    alignItems: "center",
    backgroundColor: "#282828",
    position: "relative",

    "&:hover": {
      backgroundColor: "#161616", //one off color
    },
  },
  exhibitorListItemLogo: {
    backgroundColor: `${color.white}`,
    width: "128px",
    minHeight: `${qvru.o7}`,
    borderRight: `1px solid ${color.black70}`,
    padding: `${qvru.o2}`,
    position: "relative",

    "& img": {
      width: "100%",
      display: "block",
    },
  },
  exhibitorListItemName: {
    padding: `${qvru.o2}`,
    width: "100%",
    position: "relative",
    cursor: "pointer",

    "& a": {
      color: `${color.white}`,
      display: "block",
    },
  },
  exhibitorListDescCta: {
    padding: `${qvru.o2}`,
    borderBottom: `1px solid ${color.black70}`,
    backgroundColor: color.black40,
  },
  exhibitorListDesc: {
    lineHeight: `${qvru.o3}`,
    marginBottom: `${qvru.o2}`,
  },
  exhibitorListCta: {
    textAlign: "center",
    "& a": {
      backgroundColor: `${color.black90}`,
      color: `${color.white}`,
      height: `${qvru.o4}`,
      lineHeight: "34px",
      display: "inline-block",
      borderRadius: "20px",
      padding: "0 15px",
      textTransform: "uppercase",
      textAlign: "center",
      letterSpacing: "0.1",
      transition: "all 0.4s ease 0s",

      "&:hover": {
        color: `${color.black90}`,
        backgroundColor: `${color.white}`,
      },
    },
  },
  // Audience
  audiences: {
    padding: `0 0 ${qvru.o8}`,
    position: "relative",
    marginTop: `-${qvru.o6}`,
    overflow: "hidden",

    "&:before": {
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.15)",
      zIndex: "-5",
    },
  },
  audiencesRow: {
    marginTop: "60px",
  },
  audiencesTitleWrap: {
    marginBottom: `${qvru.o2}`,

    [theme.breakpoints.up("sm")]: {
      marginBottom: `${qvru.o4}`,
      display: "grid",
      gridTemplateColumns: "repeat(12,1fr)",
      gridGap: "0",
    },
  },
  audiencesTitleWrapInner: {
    gridColumn: "4/-4",
    textAlign: "center",
  },
  audiencesContent: {
    [theme.breakpoints.up("sm")]: {
      width: "58.3333%",
    },
  },
  audiencesTitle: {
    textShadow: "0 2px 5px #1C1C49",
    marginBottom: `${qvru.o2}`,
  },
  audiencesBody: {
    textShadow: "0 2px 5px #1C1C49",
    lineHeight: `${qvru.o4}`,
  },
  audiencesContentInner: {
    position: "relative",
    backgroundColor: "rgba(26,26,26,0.75)",
    backdropFilter: `blur(${qvru.o4})`,
    padding: `${qvru.o2}`,
  },
  audiencesContentRow: {
    display: "flex",
    marginBottom: `${qvru.o2}`,
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  audienceImg: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "188px",
      paddingRight: `${qvru.o3}`,
    },
  },
  audienceContent: {
    flex: "1",
  },
  audienceBody: {
    position: "relative",
  },
  //TV
  tvRow: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)",
    gridGap: "0",
  },
  tvRowInner: {
    gridColumn: "1/-1",
    height: "100%",
    display: "flex",
    margin: `0 -${qvru.o2} ${qvru.o4}`,
  },
  tvChannelRow: {
    display: "flex",
    width: "100%",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  tvChannel: {
    width: "100%",
    marginBottom: `${qvru.o2}`,

    [theme.breakpoints.up("md")]: {
      width: "33.3333%",
      marginBottom: "0",
    },
  },
  tvImg: {
    display: "block",
  },
  tvChannelTitle: {
    marginBottom: `${qvru.o1}`,
    textAlign: "center",
  },
  tvChannelBody: {
    lineHeight: `${qvru.o3}`,
  },
  tvChannelInner: {
    margin: `0 ${qvru.o2}`,
  },
  tvChannelTitleBodyWrap: {
    padding: `${qvru.o2} 0`,
  },
  tvSection: {
    padding: `${qvru.o12} 0`,
    position: "relative",
    marginBottom: `${qvru.o6}`,
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/believe-in-music-week-tv-channels.jpg)",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",

    "&:hover": {
      "& $tvTitleImg": {
        backgroundPosition: "-64px 0",
      },
    },
  },
  tvChannelWrap: {
    backgroundColor: "rgba(26,26,26,0.85)",
    padding: `${qvru.o2}`,
    backdropFilter: "blur(36px)",
    boxShadow: "0 0 10px #111",
  },
  tvTitleImgWrap: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  tvTitleImg: {
    width: "64px",
    height: "44px",
    backgroundImage:
      "url(https://content-believe-in-music.s3.us-west-2.amazonaws.com/bim_tv_icon_2_5197a120eb.png)",
    backgroundSize: "127px",
    transition: "background 0.2s ease 0s",
  },
  tvTitle: {
    flex: "1",
    lineHeight: `${qvru.o6}`,
  },
  tvBody: {
    lineHeight: `${qvru.o4}`,
    width: "100%",

    "& p": {
      margin: "0",
    },
  },
  tvTitleImgSize: {
    width: "100%",
  },
  tvTitleH: {
    marginTop: "0",
    marginBottom: "0",
    paddingLeft: `${qvru.o1}`,
  },
  tvIntro: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)",
    gridGap: "0",
    marginBottom: `${qvru.o3}`,
  },
  tvIntroInner: {
    gridColumn: "1/-1",
    flexWrap: "wrap",
    height: "100%",
    display: "flex",
    margin: "0 0 18px",
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      gridColumn: "4/-4",
      margin: `0 -${qvru.o2} ${qvru.o2}`,
    },
  },
  //Training
  trainingRow: {
    display: "flex",
    width: "100%",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: `${qvru.o3}`,
    },
  },
  trainingImgWrap: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  trainingImg: {
    width: `${qvru.o6}`,
    height: "44px",
    backgroundImage:
      "url(https://content-believe-in-music.s3.us-west-2.amazonaws.com/bim_training_2_7b52603f25.png?37208468.21000008)",
    backgroundSize: "127px",
    transition: "background 0.2s ease 0s",
  },
  //Nav
  mobileNavLink: {
    color: `${color.black60}`,
    marginRight: `${qvru.o2}`,
    fontFamily: `${font.body}`,
    textTransform: "uppercase",
    padding: '0 18px',

    "&:hover": {
      color: `${color.primary}`,
    },
  },
  navLink: {
    marginRight: `${qvru.o2}`,
    textTransform: "uppercase",
    fontFamily: "acumin-pro-condensed, sans-serif",
    color: "#0167a9",

    "&:hover": {
      color: `${color.primary}`,
    },
  },
  navLinkWrap: {
    "& $navLink:last-child": {
      marginRight: "0",
    },
  },
  navbar: {
    backgroundColor: `${color.white}`,
    position: "relative",
    paddingTop: `${qvru.o2}`,
    paddingBottom: `${qvru.o2}`,

    "&:after": {
      height: "4px",
      width: "100%",
      content: '""',
      backgroundImage: "linear-gradient(to right, #f8f6fb, #ece9ef)",
      position: "absolute",
      bottom: "-4px",
      left: "0",
    },
  },
  //Header
  logoIcon: {
    marginRight: theme.spacing(2),
    width: "100%",
    textAlign: "left",
    display: "block",
  },
  headerMax: {
    maxWidth: "1328px",
    margin: "0 auto",
    width: "100%",
  },
  headerLogoAndInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  headerEventInfo: {
    padding: "4.5px 0 4.5px 13.5px",
    borderLeft: "3px solid #ece9ef",
    marginLeft: "13.5px",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  subscribe: {
    marginBottom: "36px",
  },
  subscribeContainer: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridGap: "0 36px",
      gridTemplateColumns: "repeat(12,1fr)",
    },
  },
  subscribeContainerInner: {
    gridColumn: "1/-1",

    [theme.breakpoints.up("md")]: {
      gridColumn: "3/-3",
    },
  },
  subscribeIntro: {
    textAlign: "center",
  },
  subscribeTitle: {
    "& .MuiTypography-h2": {
      marginBottom: "18px",
    },
  },
  subscribeText: {
    marginBottom: "36px",
  },
  headerEventLocation: {
    "& .MuiTypography-body1": {
      lineHeight: "27px",
      fontWeight: "bold",
      letterSpacing: "0.25px",
    },
  },
  headerEventDate: {
    "& .MuiTypography-body1": {
      lineHeight: "27px",
      fontWeight: "bold",
      letterSpacing: "0.25px",
    },
  },
  headerEventInfoe: {
    display: "flex",
    flexWrap: "wrap",
  },
  logoButton: {
    width: "125px",
  },
  //Accordion
  sectionAccordion: {
    padding: `${qvru.o4} 0`,
  },
  sectionAccordionQuestion: {
    margin: 0,
  },
  sectionAccordionHeading: {
    textAlign: "center",
  },

  //Event List
  eventListSection: {
    display: "flex",
    overflow: "hidden",
    marginBottom: `${qvru.o6}`,

    [theme.breakpoints.up("md")]: {
      overflow: "visible",
    },
  },
  eventListFilters: {
    width: "100%",
    top: "105%",
    left: "0",
    background: `${color.black60}`,
    position: "fixed",
    transition: "top 0.2s ease 0s",

    "&$eventListFiltersActive": {
      top: "0",
      zIndex: "4",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
      marginRight: `${qvru.o6}`,
      position: "relative",
      padding: "0",
      background: "transparent",
    },
  },
  eventListFiltersActive: {
    display: "block",
  },
  eventListFiltersInner: {
    padding: `${qvru.o2}`,
    height: `calc(100vh - ${qvru.o6})`,
    overflowY: "scroll",

    [theme.breakpoints.up("md")]: {
      background: "transparent",
      overflowY: "visible",
      height: "auto",
      padding: "0",
    },
  },
  eventListMobileTitle: {
    flex: "1",
  },
  eventListItems: {
    flex: "1",

    "& $eventItemWrap:first-child $eventDay": {
      marginTop: "0",
    },
  },
  eventDay: {
    background: "#4156d0",
    height: `${qvru.o4}`,
    textAlign: "center",
    marginTop: `${qvru.o4}`,
    marginBottom: "1px",
  },
  eventListMobileHeader: {
    background: `${color.black90}`,
    padding: `0 ${qvru.o2}`,
    display: "flex",

    "& button": {
      marginBottom: "0",
      top: "-3px",
      position: "relative",
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  //
  regMain: {
    backgroundColor: color.blue,
    minHeight: "calc(100vh - 155px)",
    padding: `${qvru.o8} 0`,
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/bim-inverse.jpg)",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  regMainInner: {
    width: "100%",
  },

  grow: {
    flexGrow: 1,
  },

  listItem: {
    width: "100%",
  },
  socialIcon: {
    color: "white",
    width: "40px !important",
    height: "40px !important",
    fontSize: 40,
    paddingLeft: theme.spacing(1),
    margin: `0 ${qvru.o1}`,
    transition: "color 0.4s ease 0s",

    "&:hover": {
      color: `${color.primary}`,
    },
  },
  containerConstrain: {
    width: "100%",
    position: "relative",
    display: "block",
  },
  containerConstrainInnerMessage: {
    borderTop: "1px solid #efefef",
    paddingTop: `${qvru.o1}`,

    "&:after": {
      content: "Scroll vertically to see more fields/options",
      width: "100%",
      textTransform: "uppercase",
      borderTop: `1px solid ${color.lightGrey}`,
      position: "absolute",
      textAlign: "center",
      height: `${qvru.o4}`,
      left: "0",
      lineHeight: `${qvru.o4}`,
      fontSize: "12px",
      color: "#a5702d",
    },
  },
  containerConstrainInner: {
    height: "300px",
    overflowY: "scroll",
  },
  root: {
    flexGrow: 1,
    padding: `${qvru.o2}`,
  },

  columnsInner: {
    padding: `0 ${qvru.o2}`,
  },
  bgPlacement: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",

    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "1",
      position: "absolute",
      background: "rgba(0,0,0,0.3)",
    },
  },
  stencilBg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/stencil-2.png)",
  },
  tvBg: {
    width: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/tv-bg.jpg)",
    animation: "tvShow 30s infinite",
  },
  trainingBg: {
    width: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/training-bg.jpg)",
    animation: "trainingShow 30s infinite",
  },
  marketplaceBg: {
    width: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/marketplace-bg.jpg)",
    animation: "mpShow 30s infinite",
  },
  bimBg: {
    width: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    backgroundImage:
      "url(https://static-believeinmusic.s3-us-west-2.amazonaws.com/bim-bg.jpg)",
    animation: "bimShow 30s infinite",
  },
  negativeMargin: {
    marginTop: `-${qvru.o8}`,
    paddingBottom: `${qvru.o8}`,
  },
  trainingInner: {
    backgroundColor: `${color.black50}`,
    margin: `0 ${qvru.o2}`,
    boxShadow: "0 0 25px #131313",
    height: "100%",
  },
  trainingTitleBodyWrap: {
    padding: `${qvru.o2}`,
  },
  trainingSection: {
    padding: `${qvru.o8} 0 ${qvru.o2}`,

    "&:hover": {
      "& $trainingImg": {
        backgroundPosition: "-64px 0",
      },
    },
  },
  containerMinHeight: {
    height: "calc(100vh - 155px)",
    width: "100%",
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-30px", //Assuming CircularProgress size is 60
    marginTop: "-30px", //Assuming CircularProgress size is 60
  },
  paginationButtons: {
    textAlign: "center",
    marginTop: `${qvru.o4}`,
  },
  instructionText: {
    color: "",
    width: "100%",
    marginBottom: `${qvru.o1}`,
  },
  checkBoxLabelWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  radioFlex: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    paddingBottom: `${qvru.o1}`,
  },
  checkBoxWrap: {
    width: "auto",
  },
  checkBoxLabel: {
    flex: "1",

    "& label": {
      color: color.grayBlue,
      display: "block",
    },
  },
  formInnerOptionsWrap: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: `${qvru.o1}`,
  },
  instructionTextBody: {
    color: "#4B5D77",
    fontSize: "12px",
    lineHeight: `${qvru.o3}`,
    paddingLeft: "43px",
  },
  positionCheckBox: {
    position: "relative",
  },
  formRadioText: {
    position: "relative",
    color: color.grayBlue,
    display: "flex",
    alignItems: "center",
    margin: "0 0 9px 0",
    width: "100%",

    "& div": {
      color: color.grayBlue,
    },

    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  formRadioLabel: {
    position: "relative",
    marginLeft: "13px",
    textTransform: "capitalize",
  },
  formLabelWrap: {
    marginBottom: `${qvru.o2}`,
  },
  formCheckBoxOption: {
    position: "relative",
    textTransform: "capitalize",
    color: color.grayBlue,
  },
  checkBoxHalf: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "&: label": {
      color: color.grayBlue,
      textTransform: "capitalize !important",
    },

    "&: select": {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  selectHalf: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingRight: `${qvru.o2}`,
    flex: "1",
  },
  checkBoxLabelNormalize: {
    textTransform: "capitalize",
    color: color.grayBlue,
  },
  styleLabel: {
    color: "#003864",
    width: "100%",
    marginBottom: "4.5px",
  },
  whitebg: {
    backgroundColor: `${color.white}`,
    color: `${color.black60}`,
  },
  darkbg: {
    backgroundColor: `${color.black50}`,
  },
  darkText: {
    color: `${color.black60}`,
  },
  darkTextH: {
    color: `${color.black60}`,
  },
  headerCta: {
    color: `${color.primary}`,
    padding: `0 ${qvru.o2}`,
    borderRadius: "20px",
    textTransform: "uppercase",
    transition: "all 0.4s ease 0s",

    "&:hover": {
      backgroundColor: `${color.white}`,
      color: `${color.black60}`,
    },
  },
  gridInner: {
    marginBottom: `${qvru.o2}`,
  },
  faqBar: {
    height: `${qvru.o6}`,
    backgroundRepeat: "no-repeat",
    backgroundColor: `${color.indigo}`,
    backgroundImage:
      "url(https://content-believe-in-music.s3.us-west-2.amazonaws.com/faq_heading_728b547145.png?28116.64500000188)",
  },
  eventName: {
    fontFamily: `${font.main}`,
    color: `${color.white}`,
    fontSize: `${qvru.o2}`,
    textTransform: "uppercase",
  },
  eventDate: {
    color: `${color.lightBlue}`,
  },
  eventInner: {
    backgroundColor: `${color.black50}`,
    margin: `0 ${qvru.o2}`,
    display: "flex",
    height: "100%",
    boxShadow: "0 3px 5px #101010",
    borderBottom: "1px solid #131313",

    [theme.breakpoints.up("md")]: {
      borderBottom: "0 none",
    },
  },
  eventAccent: {
    width: `${qvru.o1}`,
    background: `${color.indigo}`,
  },
  eventContent: {
    flex: "1",
    padding: `${qvru.o1} ${qvru.o2} 13.5px`,
  },
  eventSectionTitle: {
    textAlign: "center",
    marginBottom: `${qvru.o2}`,
  },

  [theme.breakpoints.up("md")]: {
    eventRow: {
      marginBottom: `${qvru.o2}`,
    },
  },
  eventInfo: {
    backgroundColor: `${color.indigo}`,
    cursor: "pointer",
  },
  eventInfoInner: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${color.black70}`,
    boxShadow: `0 3px 5px ${color.black70}`,
  },
  eventItemTitle: {
    flex: "1",
    background: `${color.black50}`,
    padding: `${qvru.o1} ${qvru.o2}`,
    cursor: "pointer",
    display: "block",
    borderLeft: `1px solid ${color.black70}`,
    transition: "background 0.4s ease 0s",

    "&:hover": {
      background: "#161616",
    },

    "& h3": {
      margin: "0",
    },

    "& span": {
      color: `${color.lightBlue}`,
    },
  },
  eventItemDate: {
    width: "80px",
    textAlign: "center",
  },
  rowNegative: {
    margin: `0 -${qvru.o1}`,
  },
  eventDateMonth: {
    textTransform: "uppercase",
    marginTop: "-5px",
  },
  eventItemDesc: {
    background: color.black40,
    padding: `${qvru.o2}`,
    wordBreak: "break-word",
    "& p": {
      marginTop: "0",
      lineHeight: `${qvru.o3}`,
    },
  },
  eventItemDescInner: {
    borderTop: "1px solid #1d1d1d",
    marginTop: `${qvru.o1}`,
    paddingTop: `${qvru.o1}`,
  },
  eventItemWrap: {
    marginBottom: "0",
  },
  //Article Section
  articleSection: {
    marginBottom: `${qvru.o2}`,

    "& .MuiPaginationItem-root": {
      color: `${color.white}`,
    },

    "& .MuiPagination-ul": {
      display: "inline-flex",
      left: "50%",
      position: "relative",
      transform: "translateX(-50%)",
    },
  },
  articleLayout: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  articleTitle: {
    textTransform: "uppercase",
    textAlign: "center",

    "& h1": {
      margin: "0 0 36px 0",
    },
  },
  articleBody: {
    position: "relative",
    flex: "1",
  },
  articleImg: {
    marginBottom: `${qvru.o4}`,
    display: "block",

    "& img": {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridGap: "0 40px",
      gridTemplateColumns: "repeat(12,1fr)",
    },
  },
  articleBanner: {
    background: "rgba(0,0,0,0.18)",
    borderBottom: `1px solid ${color.black90}`,
    padding: `${qvru.o3} 0`,
    marginBottom: `${qvru.o3}`,
  },
  articleImgInner: {
    boxShadow: "0 0 33px #111",

    [theme.breakpoints.up("md")]: {
      gridColumn: "2/-2",
    },
  },
  articleContent: {
    marginBottom: `${qvru.o6}`,

    "& p": {
      lineHeight: `${qvru.o4}`,
    },
  },
  articleItem: {
    display: "flex",
    marginBottom: `${qvru.o4}`,
    cursor: "pointer",

    "&:hover $articleItemInner:before": {
      width: "100%",
    },

    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "33.3333%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%",
    },
  },
  articleItemInner: {
    background: `${color.black50}`,
    boxShadow: "0 3px 5px #101010",
    flexDirection: "column",
    margin: `0 ${qvru.o2}`,
    height: "100%",
    position: "relative",

    "&:before": {
      width: "0",
      height: "100%",
      content: '""',
      position: "absolute",
      background: "linear-gradient(to right, #4C328A,#255BA2)",
      transition: "width 0.2s ease 0s",
    },

    "& a": {
      display: "block",
      textDecoration: "underline",
      height: "100%",
    },
  },
  articleItemImg: {
    width: "100%",
    position: "relative",
    "& img": {
      width: "100%",
      display: "block",
    },
  },
  articleItemContent: {
    flex: "1",
    position: "relative",
    padding: `${qvru.o2}`,
  },
  articleItemTitle: {
    "& a": {
      color: "#fff",
    },
  },
  articleSectionTitle: {
    textAlign: "center",
  },
  //Save The Date
  saveTheDate: {
    marginBottom: `${qvru.o5}`,

    "& h2": {
      textAlign: "center",
    },
  },

  saveTheDateImgs: {
    display: "flex",
    flexDirection: "column",

    "& img": {
      width: "100%",
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  saveTheDateImg: {
    marginBottom: `${qvru.o2}`,
    "& img": {
      boxShadow: "0 0 25px #131313",
    },
    "& a": {
      display: "block",
      cursor: "pointer",
    },

    [theme.breakpoints.up("md")]: {
      padding: `0 ${qvru.o2}`,
      marginBottom: "0",
      "&:first-child": {
        paddingLeft: "0",
      },
      "&:last-child": {
        paddingRight: "0",
      },
    },
  },
  formControl: {
    minWidth: 120,
    width: "100%",

    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        color: `${color.white}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${color.indigo}`,
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${color.white}`,
      transition: "border 0.4s ease 0s",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: `${color.white}`,
  },
  legendLabel: {
    color: "#9cdaff",
    marginBottom: `${qvru.o1}`,
  },
  legendSection: {
    marginBottom: `${qvru.o2}`,
  },
  helpText: {
    color: `${color.white}`,
    fontStyle: "italic",
  },
  warningText: {
    color: "red",
  },
  pageH1: {
    marginBottom: `${qvru.o3}`,

    [theme.breakpoints.up("md")]: {
      marginBottom: `${qvru.o4}`,
    },
  },
  sectionCta: {
    marginBottom: `${qvru.o4}`,
    textAlign: "center",
  },
  timeZoneMessage: {
    marginBottom: `${qvru.o2}`,
  },
  dayFilterHelper: {
    background: "#282828",
    color: `${color.white}`,
    marginBottom: `${qvru.o2}`,

    "& $formControl": {
      margin: "0",
    },

    "& $formControl svg": {
      margin: "0",
      fill: `${color.white}`,
    },
    "& $formControl svg.MuiSvgIcon-root": {
      position: "relative",
      top: "5px",
    },
    "& $formControl svg.MuiSvgIcon-root.MuiSelect-iconOutlined": {
      top: "3px",
    },
    "& $selectEmpty": {
      margin: "0",
      color: `${color.white}`,
    },
  },
  calloutPrimary: {
    backgroundColor: "#f2ecd8",
    color: `${color.black60}`,
    padding: `${qvru.o2}`,
    borderRadius: "4px",

    "& a": {
      textDecoation: "underline",
    },
  },
  featuredVideo: {
    backgroundColor: `${color.black50}`,
    display: "flex",
    flexDirection: "column",
    marginBottom: `${qvru.o6}`,

    "& iframe": {
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  featuredVideoContent: {
    padding: `${qvru.o2}`,
    width: "100%",
    background: `${color.black90}`,

    [theme.breakpoints.up("md")]: {
      width: "364px",
    },
  },
  featuredVideoIframe: {
    flex: "1",
    position: "relative",

    "& img": {
      width: "100%",
      display: "block",
    },

    "& iframe": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      display: "block",
    },
  },
  searchInput: {
    maxWidth: "600px",
    width: "100%",
    display: "block",
    borderRadius: "4px",
    margin: `0 auto ${qvru.o4}`,
  },
  routeWrapper: {
    position: "relative",
    "-webkit-transition": "opacity 3.0s",
  },
  "routeWrapper > div": {
    position: "absolute",
  },
  eventItemWrapStripe: {
    position: "relative",
  },
  textCenter: {
    textAlign: "center",
  },
  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "#ccff51",
  },
  scheduleGlance: {
    marginBottom: "36px",

    "& .MuiContainer-root": {
      padding: "0",
    },
  },
  scheduleGlanceContainer: {
    backgroundImage:
      "url(https://www.namm.org/sites/www.namm.org/files_public/resources/bim-bg-4.png), linear-gradient(to bottom right, #F7FDFF, #E6E8F4)",
    backgroundSize: "500px, 100%",
    backgroundRepeat: "repeat, no-repeat",
    padding: "18px",

    [theme.breakpoints.up("md")]: {
      padding: "54px",
    },
  },
  scheduleGlanceDates: {
    backgroundColor: "#fff",
    boxShadow: "0 0 14px #4140744a",
    padding: "54px 27px",
    display: "flex",
    flexWrap: "wrap",

    "& $scheduleGlanceDate": {
      "&:first-child": {
        paddingLeft: "0",
        borderLeft: "0 none",
        borderBottom: "3px solid #f1f4f8",
        paddingBottom: "9px",
        marginBottom: "18px",

        [theme.breakpoints.up("md")]: {
          borderBottom: "0 none",
          paddingBottom: "0",
          marginBottom: "0",
        },
      },
    },
  },
  scheduleGlanceDate: {
    width: "100%",

    "& ul": {
      padding: "0 0 0 18px",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "0",
    },
    "& li": {
      color: "#aeb2ce",
      lineHeight: "27px",
      width: "100%",
      paddingRight: "18px",
      marginBottom: "18px",

      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
    },
    "& li span": {
      color: "#4a4a6e",
    },

    [theme.breakpoints.up("md")]: {
      width: "50%",
      borderLeft: "3px solid #f1f4f8",
      paddingLeft: "54px",
    },
  },
  scheduleGlanceDateInfo: {
    textAlign: "center",

    "& h4": {
      color: "#5652a3",
    },
  },
  scheduleGlanceIntro: {
    textAlign: "center",
    display: "grid",
    gridGap: "0 36px",
    gridTemplateColumns: "repeat(12,1fr)",
    marginBottom: "36px",
  },
  scheduleGlanceIntroInner: {
    gridColumn: "2/-2",

    [theme.breakpoints.up("md")]: {
      gridColumn: "3/-3",
    },
  },
  scheduleGlanceTitle: {
    "& .MuiTypography-h2": {
      marginBottom: "18px",
    },
  },
  intro: {
    marginBottom: "54px",
  },
  introContent: {
    textAlign: "center",
    display: "grid",
    gridGap: "0 36px",
    gridTemplateColumns: "repeat(6,1fr)",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(12,1fr)",
    },
  },
  introContentInner: {
    gridColumn: "1/-1",

    [theme.breakpoints.up("md")]: {
      gridColumn: "2/-2",
      padding: "0 54px",
    },
  },
  introDescription: {
    "& .MuiTypography-body1": {
      fontSize: "18px",
      lineHeight: "27px",

      [theme.breakpoints.up("md")]: {
        fontSize: "28.8px",
        lineHeight: "54px",
      },
    },
  },
  eventPilars: {
    marginBottom: "54px",

    [theme.breakpoints.up("md")]: {
      "& .MuiContainer-root": {
        padding: "0",
      },
    },
  },
  eventPilarsCardImg: {
    "& img": {
      width: "100%",
    },
  },
  eventPilarsCardInner: {
    height: "100%",
    boxShadow: "0 9px 36px rgba(0,0,0,0.06)",
  },
  eventPilarsCard: {
    marginBottom: "27px",

    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
  },
  eventPilarsContent: {
    "& $eventPilarsCard": {
      padding: "0 18px",
    },
  },
  eventPilarsCardInfo: {
    textAlign: "center",
    padding: "0 18px 36px",
  },
  eventPilarsCardTitle: {
    "& .MuiTypography-h2": {
      fontSize: "20.25px",
      lineHeight: "36px",
      marginBottom: "18px",
    },
  },
  aCta: {
    fontWeight: "bold",

    "& a": {
      letterSpacing: "0.16px",
      position: "relative",
      color: '#1caeff',

      "&:after": {
        position: "absolute",
        height: "2px",
        display: "block",
        backgroundColor: "#E1EFF1",
        content: '""',
        bottom: "-9px",
        width: "100%",
        left: "0",
      },
      "&:hover": {
        color: "#3A6D90",
      },
    },
  },
  zoomRoot: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "& section .MuiContainer-root": {
    padding: "0",
  },
  container: {
    [theme.breakpoints.up("md")]: {
      padding: "0",
    },
  },
  sectionIntroCentered: {
    marginBottom: "36px",
    textAlign: "center",
  },
  sectionIntroCenteredTitle: {
    "& .MuiTypography-h2": {
      marginBottom: "18px",
    },
  },
  sectionIntroText: {
    textAlign: "center",
    marginBottom: "36px",
    marginTop: "-27px",
  },
  textSectionBlock: {
    //1 or more should be wrapped in a textSection div
    marginBottom: "18px",

    [theme.breakpoints.up("md")]: {
      marginBottom: "36px",
    },
  },

  textSectionBlockHalf: {
    //1 or more should be wrapped in a textSection div
    //18px whether it is mobile or desktop
    marginBottom: "18px",
  },

  textSection: {
    "& $textSectionBlock": {
      "&:last-child": {
        marginBottom: "0",
      },
    },
    "& ul": {
      padding: "0 0 0 18px",
      display: "flex",
      flexWrap: "wrap",

      "& li": {
        lineHeight: "27px",
        marginBottom: "9px",
        width: "100%",
        color: "#AEB2CE",

        "& span": {
          color: "#4A4A6E",
        },

        [theme.breakpoints.up("md")]: {
          lineHeight: "36px",
          width: "50%",
        },
      },
    },
  },
  textSectionTitle: {
    marginBottom: "18px",
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  textSectionBody: {
    marginBottom: "27px",

    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
  },
  twoColumnImg: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "54px",
    },

    "& img": {
      width: "100%",
    },
  },
  [theme.breakpoints.up("md")]: {
    twoColumnImgReverse: {
      paddingLeft: "54px",
      paddingRight: "0 !important",
    },
  },

  interactiveBrandPages: {
    marginBottom: "36px",
  },
  education: {
    marginBottom: "72px",
    overflow: "hidden",
  },
  directoryInner: {
    backgroundColor: "#ffffff",
    boxShadow: "0 9px 36px rgba(0,0,0,0.06)",
    padding: "27px 27px 0 27px",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      "&:before": {
        content: '""',
        position: "absolute",
        left: "-330px",
        top: "-60px",
        width: "436px",
        height: "810px",
        opacity: "0.6",
        zIndex: "-1",
        backgroundRepeat: "no-repeat",
        backgroundSize: "436px",
        backgroundImage:
          "url(https://www.namm.org/sites/www.namm.org/files_public/resources/education-left.png)",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        right: "-280px",
        top: "-60px",
        width: "436px",
        height: "810px",
        opacity: "0.6",
        zIndex: "-1",
        backgroundRepeat: "no-repeat",
        backgroundSize: "436px",
        backgroundImage:
          "url(https://www.namm.org/sites/www.namm.org/files_public/resources/education-right.png)",
      },
    },
  },
  directoryLogo: {
    "& img": {
      width: "100px",
    },
  },
  directoryItem: {
    marginBottom: "27px",
  },
  directoryInfo: {
    padding: "0 27px 0 27px",
  },
  whoShouldAttend: {
    backgroundImage:
      "url(https://www.namm.org/sites/www.namm.org/files_public/resources/grid-pattern-lavender-2.png)",
    backgroundSize: "54px",
    padding: "54px 0 126px",
    marginBottom: "54px",
    clipPath: "polygon(0 0, 100% 0%, 100% 98%, 0 100%)",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      clipPath: "polygon(0 0, 100% 0%, 100% 90%, 0 100%)",
    },
  },
  promotedEvents: {
    "& .MuiContainer-root": {
      padding: "0 8px",
    },
  },
  halfVruMarginTop: {
    marginTop: '18px'
  },
  oneVruMarginTop: {
    marginTop: '36px'
  },
  cardWide: {
    margin: '72px 0',
  },
  cardWideInner: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 9px 36px rgba(0,0,0,0.0547)',

    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  cardWideImg: {
    '& img': {
      display: 'block',
      width: '100%',
    },

    [theme.breakpoints.up("md")]: {
      width: '50%'
    },
  },
  cardWideInfo: {
    width: '100%',
    padding: '36px',

    [theme.breakpoints.up("md")]: {
      width: '50%',
      padding: '0 72px',
    },

    '& h2': {
      color: '#534797',
    },
  },
  cardWideCtas: {
    display: 'flex',
  },
  cardWideCta: {
    marginRight: '36px'
  },
  cardWideSectionTitle: {
    textAlign: 'center',
    marginBottom: '36px'
  },
  resourceListInner: {
    paddingTop: '54px',
    marginBottom: '72px',
    position: 'relative',

    '&:before': {
      content: '""',
      height: '4px',
      width: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      backgroundImage: 'linear-gradient(to right, transparent, #ece9ef, transparent)',
    }
  },
  resourceListTitle: {
    textAlign: 'center',
    marginBottom: '36px',

    '& .MuiTypography-h5': {
      color: '#68678b',
    },
  },
  resourceListItems: {
    maxWidth: '920px',

    [theme.breakpoints.up("md")]: {
      margin: '0 auto',
      padding: '0 72px',
      display: 'flex',
      flexWrap: 'wrap'
    },
  },
  resourceListItem: {
    width: '50%',
    marginBottom: '18px',
    paddingRight: '18px'
  },
  resourceListCta: {
    textAlign: 'center',
    marginTop: '27px',

    '& a': {
      padding: '18px 27px',
      fontWeight: 'bold',
      display: 'inline-block'
    },
  }
}));
