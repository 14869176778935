import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "../styles/global";
import {
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
} from "@material-ui/core";

const Education = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)");

  return (
    <>
      <section id="education" className={classes.education}>
        <Container className={classes.container}>
          <div className={classes.sectionIntroCentered}>
            <div className={classes.sectionIntroCenteredTitle}>
              <Typography variant="h2">Education</Typography>
            </div>
            <div className={classes.sectionIntroCenteredDescription}>
              <Box
                gridTemplateColumns={{
                  xs: "repeat(6, 1fr)",
                  md: "repeat(12, 1fr)",
                }}
                gridGap={{ xs: "18px", md: "36px" }}
                display={{ xs: "grid" }}
              >
                <Box gridColumn={{ xs: "1/-1", md: "4/-4" }}>
                  <div className={classes.textSectionBlockHalf}>
                    <Typography variant="body1">
                      Live and on-demand education sessions and content will be
                      available Thursday, January 20 and Friday, January 21, with
                      on-demand sessions extending through the weekend, powering
                      more discovery.
                    </Typography>
                  </div>
                  <div className={classes.aCta}>
                    <a  href="schedule">
                      View The Schedule
                    </a>
                  </div>
                </Box>
              </Box>
            </div>
          </div>

          {/* <div className={classes.sectionIntroCentered}>
            <div className={classes.sectionIntroCenteredTitle}>
              <Typography variant="h3">Believe In Music Tracks</Typography>
            </div>
          </div> */}
          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.directory}
          >
            <Box
              gridColumn={{ xs: "1/-1", md: "2/-2" }}
              className={classes.directoryInner}
            >
              <Grid
                container
                direction={largeScreen ? "row" : "column"}
                className={classes.directoryItems}
              >
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/namm-u.png"
                      alt="Music Business"
                      title="Music Business"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">Music Business</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Tips, strategies and new ideas to grow your retail
                        business and brand
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/tec-tracks.png"
                      alt="TEC Tracks"
                      title="TEC Tracks"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">TEC Tracks</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Sessions for sound and studio pros
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/live-event-production.png"
                      alt="Live event production"
                      title="Live event production"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">
                        Live event production
                      </Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Sessions for touring and stage pros
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/house-of-worship.png"
                      alt="House of Worship"
                      title="House of Worship"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">House of Worship</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Ideas and inspiration to elevate worship services
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/music-and-social-justice.png"
                      alt="Music and Social Justice"
                      title="Music and Social Justice"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">
                        Music and Social Justice
                      </Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Conversations about diversity and inclusion for leaders
                        of cultural organizations
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/music-education-days.png"
                      alt="Music Education Days"
                      title="Music Education Days"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">Music Education Days</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Information, inspiration and tools for today’s music
                        classrooms
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/music-and-wellness.png"
                      alt="Music and Wellness"
                      title="Music and Wellness"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">Music and Wellness</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Better living through music for lifelong learners
                        seeking health enrichment
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/nonprofit-management-institute.png"
                      alt="Nonprofit Mangement Institute"
                      title="Nonprofit Mangement Institute"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">
                        Nonprofit Mangement Institute
                      </Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Resources and best practices for fundraising and
                        governance and more
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item={true}
                  container
                  alignItems="center"
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/gennext.png"
                      alt="Gennext"
                      title="Gennext"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">Gennext</Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        College students connecting with the future of music
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  item={true}
                  xs={12}
                  md={6}
                  wrap="nowrap"
                  className={classes.directoryItem}
                  direction="row"
                >
                  <div className={classes.directoryLogo}>
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/namm-young-professionals.png"
                      alt="NAMM Young Professionals"
                      title="NAMM Young Professionals"
                    />
                  </div>
                  <div className={classes.directoryInfo}>
                    <div className={classes.directoryInfoTitle}>
                      <Typography variant="h4">
                        NAMM Young Professionals
                      </Typography>
                    </div>
                    <div className={classes.directoryInfoDescription}>
                      <Typography variant="body1">
                        Network with industry professionals under 40
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default Education;
