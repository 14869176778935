import React from "react";
import { Typography, Link } from "@material-ui/core";
import { useStyles } from "../../../styles/global";

const Hero = () => {
  const classes = useStyles();

  return (
    <>
    <div id="back-to-top-anchor" className={classes.wrapper}>
      <div className={`${classes.hero} ${classes.heroMarketing}` }>
        <div className={classes.heroRibbonBanner}></div>
        <div className={classes.heroInner}>
          <div className={classes.heroContent}>
            <div className={classes.heroContentInner}>
              <div className={classes.heroMeta}>
                <Typography variant="body1">
                  Believe In Music 2022
                </Typography>
              </div>
              <div className={classes.heroTitle}>
                <Typography variant="h1">
                  Attendee Resources
                </Typography>
              </div>
              <div className={classes.heroSubTitle}>
                <Typography variant="body1">
                  Please utilize these resources to get started with the platform, build your schedule and enjoy a great event!
                </Typography>
              </div>
              <div className={classes.heroCtas}>
                <ul>
                  <li>
                    <Link
                      href="#schedule"
                      underline="none"
                    >
                      Schedule
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="#promo"
                      underline="none"
                    >
                      Promo Kit
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="#download"
                      underline="none"
                    >
                      Download App
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.believeinmusic.tv/event/believe-in-music-2022"
                      underline="none"
                      className={classes.primaryCta}
                      target="_blank"
                    >
                      Log in
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.heroImg}>
          <img
            src="https://www.namm.org/sites/www.namm.org/files_public/resources/plan-your-show-2.jpg"
            alt="Plan Your Show"
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default Hero;
