import React from "react";
import { useStyles } from "../styles/global";
import {
  Container,
  Typography,
  Grid,
  Box,
  useMediaQuery,
} from "@material-ui/core";

const BmTv = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)");

  return (
    <>
      <section id="tv" className={classes.interactiveBrandPages}>
        <Container className={classes.container}>
          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.redTest}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-1" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column"}
              >
                <Grid item={true} xs={12} md={4}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h2">
                          Believe In Music TV
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          NAMM President and CEO Joe Lamond and Pensado’s Place
                          co-founder Herb Trawick will once again showcase live
                          musical performances, exclusive artist interviews, and
                          experiences to connect and inspire.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <div
                    className={`${classes.twoColumnImg} ${classes.twoColumnImgReverse}`}
                  >
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-tv.png"
                      alt="Live musical performances, exclusive artist interviews, and experiences on Believe In Music TV"
                      title="Live musical performances, exclusive artist interviews, and experiences on Believe In Music TV"
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default BmTv;
