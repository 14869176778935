import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { IAppContextInterface, withAppContext } from "../../../AppContext";
import { Typography, Link } from "@material-ui/core";
import { useStyles } from "../../../styles/global";

const Hero = ({ appContext }: { appContext: IAppContextInterface }) => {
  const classes = useStyles();
  const homePage: any = appContext.pageContent.homePage.data;

  return (
    <div id="back-to-top-anchor" className={classes.wrapper}>
      <div className={classes.hero}>
        <div className={classes.heroRibbonBanner}></div>
        <div className={classes.heroInner}>
          <div className={classes.heroContent}>
            <div className={classes.heroContentInner}>
              <div className={classes.heroMeta}>
                <Typography variant="body1">
                  {homePage.attributes.field_hero_super_title}
                </Typography>
              </div>
              <div className={classes.heroTitle}>
                <Typography variant="h1">
                  {homePage.attributes.field_hero_title}
                </Typography>
              </div>
              <div className={classes.heroSubTitle}>
                <Typography variant="body1">
                  {homePage.attributes.field_hero_subtitle}
                </Typography>
              </div>
              <div className={classes.heroCtas}>
                <ul>
                  {/* <li>
                                    <Link to="/exhibit">Exhibit</Link>
                                </li>
                                <li>
                                    <Link to="/sponsor">Sponsor</Link>
                                </li> */}
                  <li>
                    <Link
                      href="https://www.believeinmusic.tv/event/believe-in-music-2022"
                      underline="none"
                    >
                      Log in
                    </Link>
                  </li>
                  <li>
                    <Link
                      //href="/#signup" <-- sign up form anchor link
                      href="https://registration.namm.org/register/bm22?ms=bmdtv"
                      underline="none"
                      className={classes.primaryCta}
                    >
                      Register
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.heroImg}>
          <img
            src="https://ww2.namm.org/sites/default/files/2021-10/bim-hero.jpeg"
            alt="The Music Starts here at the NAMM Show"
          />
        </div>
      </div>
    </div>
  );
};

export default withAppContext(Hero as React.FunctionComponent);
