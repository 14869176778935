import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import HowToRegIcon from '@material-ui/icons/HowToReg';
// import MoreIcon from '@material-ui/icons/More';
// import TwitterIcon from '@material-ui/icons/Twitter'
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import { Links } from '../data/links'
import MenuIcon from "@material-ui/icons/Menu";
import NAMMLogo from "../assets/bim_full_logo.svg";
import { useStyles } from "./../styles/global";
export default function Navbar() {
  // Temporary: for mimicking logged in state.
  //const isLoggedIn = false;

  const classes = useStyles();
  // removed el from below
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [el, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  //const isUserMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleLogin = (event: React.MouseEvent<HTMLElement>) => {
  //     alert('Login stuff here');
  // }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleUserMenuClose = () => {
  //     setAnchorEl(null);
  //     handleMobileMenuClose();
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //     setAnchorEl(null);
  //     handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  //const UsermenuId = 'account-menu';
  const mobileMenuId = "menu-mobile";

  // const renderUserMenu = () => {
  //     if(isLoggedIn) {
  //         return(
  //           <Menu
  //             anchorEl={anchorEl}
  //             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //             id={UsermenuId}
  //             keepMounted
  //             transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //             open={isUserMenuOpen}
  //             onClose={handleUserMenuClose}
  //           >
  //             <MenuItem onClick={handleUserMenuClose}>Profile</MenuItem>
  //             <MenuItem onClick={handleUserMenuClose}>My account</MenuItem>
  //           </Menu>
  //         )
  //     } else {
  //         return(
  //             <Menu
  //                 anchorEl={anchorEl}
  //                 anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //                 id={UsermenuId}
  //                 keepMounted
  //                 transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //                 open={isUserMenuOpen}
  //                 onClose={handleUserMenuClose}
  //             >
  //                 <MenuItem onClick={handleLogin}>Login</MenuItem>
  //             </Menu>
  //         )
  //     }
  // }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                >
                <AccountCircleIcon />
                </IconButton> */}
      {/* <MenuItem onClick={handleProfileMenuOpen}>
                <a className={classes.navLink} href="https://www.believeinmusic.tv">Login</a>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <Link className={classes.mobileNavLink} to="/register">Register</Link>
            </MenuItem>

            <MenuItem onClick={handleProfileMenuOpen}>
                <Link className={classes.mobileNavLink} to="/marketplace">Marketplace</Link>
            </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <a
          className={classes.mobileNavLink}
          href="https://registration.namm.org/register/bm22"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register
        </a>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
          <Link className={classes.mobileNavLink} to="/attendee-resources">Attendee Resources</Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
          <Link className={classes.mobileNavLink} to="/schedule">Schedule</Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <Link className={classes.mobileNavLink} to="/faqs">
          FAQs
        </Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <a
          className={classes.mobileNavLink}
          href="https://www.namm.org/believe-in-music/sponsor"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sponsorships
        </a>
      </MenuItem>
      {/* <MenuItem>
                <IconButton
                    aria-label="Link to NAMM Twitter"
                    color="inherit"
                    href={Links.twitter}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <TwitterIcon />
                </IconButton>
            </MenuItem>
            <MenuItem>
                <IconButton
                    aria-label="Link to NAMM Facebook"
                    color="inherit"
                    href={Links.facebook}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <FacebookIcon />
                </IconButton>
            </MenuItem>
            <MenuItem>
                <IconButton
                    aria-label="Link to NAMM Instagram"
                    color="inherit"
                    href={Links.instagram}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <InstagramIcon />
                </IconButton>
            </MenuItem> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        color="secondary"
        className={classes.navbar}
        elevation={0}
      >
        <Toolbar className={classes.headerMax}>
          <div className={classes.headerLogoAndInfo}>
            <div className={classes.headerLogo}>
              <Link to="/">
                <div className={classes.logoButton}>
                  <img
                    className={classes.logoIcon}
                    src={NAMMLogo}
                    alt="Believe In Music Week"
                    title="Believe In Music Week"
                  />
                </div>
              </Link>
            </div>
            <div className={classes.headerEventInfo}>
              <div className={classes.headerEventLocation}>
                <Typography variant="body1">January 20-21, 2022</Typography>
              </div>
            </div>
          </div>

          {/* <Typography className={classes.title} variant="h6" noWrap>
                        NAMM
                    </Typography> */}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div className={classes.navLinkWrap}>
              {/* <a
                className={classes.navLink}
                href="https://www.believeinmusic.tv"
              >
                Login
              </a>
              <Link className={classes.navLink} to="/schedule">
                Full Schedule
              </Link>
              <Link className={classes.navLink} to="/marketplace">
                Marketplace
              </Link> */}
              <a
                className={classes.navLink}
                href="https://registration.namm.org/register/bm22?mc=bmdtv-nav"
              >
                Register
              </a>
              <Link className={classes.navLink} to="/attendee-resources">
                Attendee Resources
              </Link>
              <Link className={classes.navLink} to="/schedule">
                Schedule
              </Link>
              <Link className={classes.navLink} to="/faqs">
                FAQs
              </Link>
              <a
                className={classes.navLink}
                href="https://www.namm.org/believe-in-music/sponsor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sponsorships
              </a>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {/* {renderUserMenu()} */}
    </div>
  );
}
