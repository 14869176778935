import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollHandler({ history }: {history: any}) {
  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (null);
}

export default withRouter(ScrollHandler);
