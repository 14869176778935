import React, { useEffect } from "react";
import { useStyles } from "./styles/global";
import { IAppContextInterface, withAppContext } from "./AppContext";
import { Switch, Route, useLocation } from "react-router-dom";
//import { spring, AnimatedSwitch } from 'react-router-transition';
import NotFound from "./components/NotFound";
import CircularProgress from "@material-ui/core/CircularProgress";
import LandingPage from "./pages/LandingPage/LandingPage";
import PlanYourShow from "./pages/PlanYourShowPage/PlanYourShow";
// import { RegistrationPage } from "./pages/RegistrationPage/RegistrationPage";
// import ExhibitorListPage from "./pages/ExhibitorListPage/ExhibitorListPage";
// import { VerifyEmail } from './components/VerifyEmail';
import { Privacy } from "./pages/PrivacyPage/PrivacyPage";
import { CodeOfConduct } from "./pages/CodeOfConductPage/CodeOfConductPage";
import Faqs from "./pages/FaqsPage/FaqsPage";
import Terms from "./pages/TermsPage/TermsPage";
import SwapCardSessions from "./pages/SwapCardSessions/SwapCardSessions";
// import Success from "./pages/SuccessPage/SuccessPage";
// import BIMTVPage from "./pages/BIMTVPage/BIMTVPage";
// import Article from "./pages/ArticlePage/ArticlePage";
// import Events from "./pages/EventsPage/EventsPage";
//import classes from '*.module.css';

const RoutesList = () => {
  const { pathname } = useLocation();

  const iframe =
    '<iframe src="https://www.believeinmusic.tv/widget/event/believe-in-music-2022/plannings/RXZlbnRWaWV3XzI4Njc5Mw==" style="border: none; margin: 0px; width: 100%; display: block; height: calc(100vh - 100px);"></iframe>'; //  -

  //const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // we need to map the `scale` prop we define below
  // to the transform style property
  // function mapStyles(styles: any) {
  //   return {
  //     opacity: styles.opacity,
  //     transform: 'opacity 0.3s',
  //   };
  // }

  // wrap the `spring` helper to use a bouncy config
  // function bounce(val: any) {
  //   return spring(val, {
  //     stiffness: 330,
  //     damping: 22,
  //   });
  // }

  // child matches will...
  // const opacityTransition = {
  //   // start in a transparent state
  //   atEnter: {
  //     opacity: 0,
  //   },
  //   // leave in a transparent, downscaled state
  //   atLeave: {
  //     opacity: 0,
  //   },
  //   // and rest at an opaque, normally-scaled state
  //   atActive: {
  //     opacity: 1,
  //   },
  // };

  return (
    <>
      {/* <AnimatedSwitch
      atEnter={opacityTransition.atEnter} //mouting
      atLeave={opacityTransition.atLeave} //unmounting
      atActive={opacityTransition.atActive} // mounted
      mapStyles={mapStyles}
      className="route-wrapper"
    > */}
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/attendee-resources">
          <PlanYourShow />
        </Route>
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/code-of-conduct" component={CodeOfConduct} />
        <Route
          exact
          path="/schedule"
          render={(props) => <SwapCardSessions {...props} iframe={iframe} />}
        />
        {/* <Route exact path="/register:inv?" component={RegistrationPage} />
        <Route exact path="/verify:code?" component={VerifyEmail} />
        <Route exact path="/success" component={Success} />
        <Route path="/article/:id" component={Article} />
        <Route path="/schedule" component={Events} />
        <Route path="/marketplace" component={ExhibitorListPage} />
        <Route path="/believe-in-music-tv" component={BIMTVPage} /> */}
        <Route component={NotFound} />
      </Switch>
      {/* </AnimatedSwitch> */}
    </>
  );
};

// Load Routes only after calls to the API are complete and succesfull.
const PageRoutes = ({ appContext }: { appContext: IAppContextInterface }) => {
  const { isLoading } = appContext;
  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <div className={classes.containerMinHeight}>
          <main>
            <div className={classes.wrapper}>
              <div className={classes.alignItem}>
                <div className={classes.centerLoading}>
                  <CircularProgress size={60} />
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <RoutesList />
      )}
    </>
  );
};

export const Routes = withAppContext(PageRoutes as React.FunctionComponent);
