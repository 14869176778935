import React from "react";
// import { components } from './types' // Generated BIM API Definitions
// import * as StrapiApiSchema from './strapi.ts/index';

// Destructure because the generated types are nested several times.
// type BimApiSchemas = components['schemas'];

// Reference the Strapi data models
interface IPageContent {
  homePage: any;
  faqs: any;
  terms: any;
  privacy: any;
  coc: any;
  promotedSessions: any;
  // registrationPage: StrapiApiSchema.IRegistration,
  // articlePage: any,
  // questions: StrapiApiSchema.IQuestion,
  // privacyPage: any,
  // successPage: any,
  // termsPage: any,
  // events: any,
  // speakers: any,
  // exhibitors: any,
}

// Strapi object
// interface IHomePage {
//     id: string;
//     title?: string;
//     subTitle?: string;
//     hero: any[];
//     path?: string;
//     iconSection?: any;
//     faqs?: StrapiApiSchema.IFAQ[];
//     sections?: StrapiApiSchema.ISection[];
//     jumpMenuSection?: any;
//     audiencesSection?: any;
//     marketplaceSection?: any;
//     trainingSection?: any;
//     bimTvSection?: any;
//     upcomingDates?: any;
//     scheduleAtAGlance?: any;
//     promotedEventSection?: any;
// }

export interface IRegistration {
  email: string;
  firstName: string;
  lastName: string;
  organization?: string | null;
  jobTitle?: string | null;
  token?: string | null;
  clientIpAddress?: string | null;
  country?: string | null;
  region?: string | null;
  language?: string | null;
  demographics?: { [key: string]: string | boolean | number | [] } | null;
  personnas?: string[] | null;
}

// Reference the BIM API data models
export interface IAppContextInterface {
  // [key: string]: any
  // steps: any,
  // currentStep: number,
  isLoading: boolean;
  // verificationToken: string,
  // invitation: BimApiSchemas['Invitation'],
  // registration: IRegistration,
  language: string;
  pageContent: IPageContent;
}

const context = React.createContext<IAppContextInterface | null>(null);

export const AppContextProvider = context.Provider;
export const AppContextConsumer = context.Consumer;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withAppContext<
  P extends { appContext?: IAppContextInterface },
  R = Omit<P, "appContext">
>(
  WrappedComponent: React.ComponentClass<P> | React.FunctionComponent<P>
): React.FunctionComponent<R> {
  return function BoundComponentHOC(props: R) {
    return (
      <AppContextConsumer>
        {(value) => <WrappedComponent {...(props as any)} appContext={value} />}
      </AppContextConsumer>
    );
  };
}
