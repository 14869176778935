import React from "react";
import { useStyles } from "../../styles/global";
import Hero from "./Sections/Hero";
import Schedule from "./Sections/Schedule";
import Support from "./Sections/Support";
import CardWide from "./Sections/CardWide";
import ResourceList from "./Sections/ResourceList";
import Intro from "./Sections/Intro";
import FeaturedVideo from "./Sections/Video";
import LoginInfo from "./Sections/LoginInfo";

const PlanYourShow = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <Hero/>
      <Intro/>
      <FeaturedVideo/>
      <LoginInfo/>
      <Schedule/>
      <Support/>
      <CardWide/>
      <ResourceList/>
    </>
  );
};

export default PlanYourShow;
