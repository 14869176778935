import React, { useEffect } from "react";
import { useStyles } from "../styles/global";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { IAppContextInterface, withAppContext } from "../AppContext";
import { Container, Typography, Grid, useMediaQuery } from "@material-ui/core";

const PromotedEvents = ({
  appContext,
}: {
  appContext: IAppContextInterface;
}) => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)");

  useEffect(() => {
    const sessions = appContext.pageContent.promotedSessions;
    console.log(sessions);
  }, [appContext]);


  return (
    <>
      <section className={classes.promotedEvents}>
        <Container>
          <div className={classes.sectionIntroCentered}>
            <div className={classes.sectionIntroCenteredTitle}>
              <Typography variant="h2">Featured Events</Typography>
            </div>
          </div>
          <Grid container direction={largeScreen ? "row" : "column"}>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-tv-promo.jpg"
                    alt="Believe TV Livestream"
                    title="Believe TV Livestream"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">Believe TV Livestream</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Joe Lamond & Herb Trawick
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-Doug-Stephens.jpg"
                    alt="The Customer of The Future"
                    title="The Customer of The Future"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM U</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">The Customer of The Future</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Doug Stephens
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-A3E-Panel.jpg"
                    alt="Artificial Intelligence, Artificial Creativity and Their Impact on Music and Content Creation"
                    title="Artificial Intelligence, Artificial Creativity and Their Impact on Music and Content Creation"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">A3E</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Artificial Intelligence, Artificial Creativity and Their
                    Impact on Music and Content Creation
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Jack Joseph Puig, Daniel Rowland, Maya Ackerman
                    and Paul Sitar
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-Chris-Lose-Phay-MacMahon.jpg"
                    alt="How to Take the Stress Out of Touring"
                    title="How to Take the Stress Out of Touring"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Pro Production</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    How to Take the Stress Out of Touring
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Chris Lose and Phay Machon
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-John-Huntington.jpg"
                    alt="50 Years of Show Technology Evolution"
                    title="50 Years of Show Technology Evolution"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Esta</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    50 Years of Show Technology Evolution
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by John Huntington
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/believe-in-music-Ayana-Webb.jpg"
                    alt="How to Use Facebook to Increase Your Lesson Students"
                    title="How to Use Facebook to Increase Your Lesson Students"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM U</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    How to Use Facebook to Increase Your Lesson Students
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Ayana Webb
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_Struts_1920x1080_WEB_NoContent.jpg"
                    alt="Luke Spiller & Adam Slack of the Struts"
                    title="Luke Spiller & Adam Slack of the Struts"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                     Luke Spiller & Adam Slack of the Struts
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    In Conversation With Lyndsey Parker
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_ElliotEaston_1920x1080_WEB_NoContent.jpg"
                    alt="Elliot Easton (The Cars)"
                    title="Elliot Easton (The Cars)"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Elliot Easton (The Cars)
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    In Live Conversation With Joe Lamond & Herb Trawick
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_SteveVai_1920x1080_WEB_NoContent.jpg"
                    alt="Steve Vai"
                    title="Steve Vai"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Steve Vai
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    In Conversation With Mr. Bonzai
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_GLS_1920x1080_PhotoOnly.jpg"
                    alt="Global Livestream"
                    title="Global Livestream"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Global Livestream
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by NAMM
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_BrandonBoyd_1920x1080_WEB_NoContent.jpg"
                    alt="Brandon Boyd"
                    title="Brandon Boyd"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Brandon Boyd
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    In Conversation with Rick Damigella
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/BM22_PromoTiles_BrittanySpencer_v2_1920x1080_WEB_NoContent.jpg"
                    alt="Brittney Spencer"
                    title="Brittney Spencer"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">Believe TV</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Brittney Spencer
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    In Conversation With Lyndsey Parker
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/big-ideas-to-improve-accesibility.jpg"
                    alt="Big Ideas To Improve Accessibility"
                    title="Big Ideas To Improve Accessibility"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM Foundation</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Big Ideas To Improve Accessibility
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented By David Cutler, Serona Elton, Marcia Neel, Dr. Donny Gruendler, Dr. Stan Renard
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/namm-music-administrations-forum.jpg"
                    alt="NAMM Music Administrators Forum (MED)"
                    title="NAMM Music Administrators Forum (MED)"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM Foundation</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    NAMM Music Administrators Forum (MED)
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Mary Luehrsen, Anne Fennell, Creston Herron, Anthony Beatrice
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/making-music-for-all-ukulele-strings.jpg"
                    alt="Music Making For All: Ukulele Strings Attached!"
                    title="Music Making For All: Ukulele Strings Attached!"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM Foundation</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Music Making For All: Ukulele Strings Attached!
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Peter Luongo and James Hill
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={3} className={classes.card}>
              <div
                className={`${classes.cardInner} ${classes.cardInnerNoShadow}`}
              >
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/embracing-diversity.jpg"
                    alt="Embracing Diversity, Equity and Opportunity"
                    title="Embracing Diversity, Equity and Opportunity"
                  />
                </div>
                <div className={classes.cardLabel}>
                  <Typography variant="body1">NAMM Foundation</Typography>
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h5">
                    Embracing Diversity, Equity and Opportunity
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Presented by Mary Luehrsen, Anne Fennell, Creston Herron, Anthony Beatrice
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default withAppContext(PromotedEvents);
