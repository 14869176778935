import React from 'react';
import { Link } from 'react-router-dom';
import {Typography} from '@material-ui/core';
import { useStyles } from '../styles/global';
import Container from '@material-ui/core/Container';

const NotFound = () => {
  const classes = useStyles();
  return(
    <section className={classes.whitebg}>
      <Container maxWidth="md">
        <div className={classes.stepHeight}>
          <div className={classes.root}>
            <div className={classes.containerConstrain}>
              <Typography variant="h2">Sorry! The page was not found.</Typography>
                <Typography variant="body1">Here's some links you might find helpful</Typography>
                <ul className={classes.textPrimary}>
                  <li><Link to="/register">Believe in Music Registration</Link></li>
                  <li><a href="https://namm.org">NAMM.org</a></li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default NotFound;
