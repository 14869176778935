
export const color = {
  black90: '#0a0a0a',
  black80: '#101010',
  black70: '#151515',
  black60: '#1e1e1e',
  black50: '#262626',
  black40: '#2b2b2b',
  lightGrey: '#999999',
  silver: '#efefef',
  primary: '#00afed',
  //'#007da9',
  grayBlue: '#383b44',
  lightBlue: '#ccecff',
  indigo: '#264397',
  white: '#ffffff',
  blue: '#0072bc',
  darkBlue: '#234560',
  orange: '#e6b222',
  darkRed: '#5c0f0f',
}

export const font = {
  sectionHeading: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
  main: 'acumin-pro-condensed, sans-serif',
  body: 'acumin-pro, sans-serif',
}

// Font Sizes
// Based on https://www.modularscale.com/?1&em&1.125
// ms = modular scale (size from the link above)
export const fontSize = {
  msN3: '11px', // 0.702em, N is for negative
  msN2: '13px', // 0.79em, N is for negative
  msN1: '14px', // 0.889em, N is for negative
  ms0: '16px', // 1em
  ms1: '18px', // 1.125em
  ms2: '20px', // 1.266em
  ms3: '23px', // 1.424em
  ms4: '26px', // 1.602em
  ms5: '29px', // 1.802em
  ms6: '32px', // 2.027em
  ms7: '36px', // 2.281em
  ms8: '41px', // 2.566em
  ms9: '46px', // 2.887em
  ms10: '52px', // 3.247em
  ms11: '58px', // 3.653em
  ms12: '66px', // 4.11em
}

// Quarter Vertical Rythmn Units
/*
  Since we do not have mixins available to us, VRUs were broken down into multiples of 9
  Tried to keep these name as short as possible.
  o = option (and will increment by 9)
*/

export const qvru = {
  o1: '9px',
  o2: '18px',
  o3: '27px',
  o4: '36px',
  o5: '45px',
  o6: '54px',
  o7: '63px',
  o8: '72px',
  o9: '81px',
  o10: '90px',
  o11: '99px',
  o12: '108px',
}
