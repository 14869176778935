import React from "react";
import { IAppContextInterface, withAppContext } from "../../AppContext";
import { Typography } from "@material-ui/core";
import marked from "marked";
import { useStyles } from "../../styles/global";
import Container from "@material-ui/core/Container";

const PrivacyPage = ({ appContext }: { appContext: IAppContextInterface }) => {
  const classes = useStyles();
  const { privacy } = appContext.pageContent;
  console.log(privacy);
  //helper component to convert markdown to html
  const markdownViewer = (markdown: string) => {
    let html = marked(markdown);
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>;
  };

  return (
    <section>
      <Container maxWidth="md">
        <div className={classes.stepHeight}>
          <div className={classes.root}>
            <div className={classes.containerConstrain}>
              <h2>{privacy.attributes.title}</h2>
              <Typography className={classes.formBodyP}>
                {markdownViewer(privacy.attributes.body.value)}
              </Typography>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export const Privacy = withAppContext(PrivacyPage as React.FunctionComponent);
