import { createMuiTheme } from '@material-ui/core';


// The NAMM Show theme
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0072BC',
        },
        secondary: {
            main: '#ca0000',
        },
    },
    typography: {
        fontFamily: 'Tablet Gothic',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    overrides: {
        IconButton: {
            '& .MuiIconButton-root': {
                padding: '0',
            },
        },
        Accordion: {
            '& .MuiAccordionSummary-root': {
            backgroundColor: '#ffffff'
            },
        },
        FormLabel: {
            '&.MuiFormLabel-root': {
                marginBottom: '18px',
            },
        },
    },
});


theme.typography.h1 = {
    fontFamily: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
    fontWeight: 'normal',
    fontSize: '25.629px',
    lineHeight: '36px',
    color: '#25225e',

    [theme.breakpoints.up('md')]: {
        fontSize: '58.452px',
        lineHeight: '72px',
    },
};

theme.typography.h2 = {
    fontFamily: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
    fontWeight: 'normal',
    fontSize: '20.2px',
    lineHeight: '27px',
    color: '#25225e',
    marginBottom: '36px',

    [theme.breakpoints.up('md')]: {
        fontSize: '36.491px',
        lineHeight: '45px',
    },
};

theme.typography.h3 = {
    fontFamily: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#25225e',

    [theme.breakpoints.up('md')]: {
        fontSize: '22.781px',
        lineHeight: '36px',
    },
};

theme.typography.h4 = {
    fontFamily: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '27px',
    color: '#25225e',

    [theme.breakpoints.up('md')]: {
        fontSize: '20.25px',
        lineHeight: '36px',
    },
};

theme.typography.h5 = {
    fontFamily: 'PhosphatePro-Solid, acumin-pro-condensed, sans-serif',
    fontWeight: 'normal',
    fontSize: '14.222px',
    lineHeight: '27px',
    color: '#25225e',

    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '36px',
    },
};

theme.typography.button = {
    fontFamily: 'acumin-pro-condensed, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '27px',
    padding: '0',

    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '36px',
    },
};

theme.typography.body1 = {
    fontFamily: 'acumin-pro, sans-serif',
    fontSize: '16px',
    lineHeight: '27px',
    padding: '0',
    color: '#4a4a6e',

    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '36px',
    },
};

theme.typography.body2 = {
    fontFamily: 'acumin-pro-condensed, sans-serif',
    fontSize: '16px',
    lineHeight: '27px',

    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '36px',
    },
};


export default theme;
