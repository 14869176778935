import React from "react";
import { useStyles } from "../../../styles/global";
import { Container, Typography, Grid, Box, useMediaQuery, Link } from "@material-ui/core";
import { ca } from "date-fns/locale";

const ResourceList = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section className={classes.resourceList}>
        <Container className={classes.container}>
          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <div className={classes.resourceListInner}>
                <div className={classes.resourceListTitle}>
                  <Typography variant="h5">
                    Questions and Resources
                  </Typography>
                </div>
                <div className={classes.resourceListItems}>
                  <div className={classes.resourceListItem}>
                    <Typography variant="body1">
                      <strong>Email:</strong> registration@namm.org
                    </Typography>
                  </div>
                  <div className={classes.resourceListItem}>
                    <Typography variant="body1">
                      <strong>Toll Free Member Hotline:</strong> 1-800-767-6266
                    </Typography>
                  </div>
                  <div className={classes.resourceListItem}>
                    <Typography variant="body1">
                      <strong>Telephone:</strong> +1.760.438.8001
                    </Typography>
                  </div>
                  <div className={classes.resourceListItem}>
                    <Typography variant="body1">
                      <strong>Fax:</strong> +1.760.438.7327
                    </Typography>
                  </div>
                  <div className={classes.resourceListItem}>
                    <Typography variant="body1">
                      <strong>Mailing Address:</strong> NAMM 5790 Armada Drive Carlsbad, CA 92008 USA
                    </Typography>
                  </div>
                </div>
                <div className={classes.resourceListCta}>
                  <Link href="faqs" className={classes.secondaryCta} underline="none">
                    View FAQs
                  </Link>
                </div>
              </div>
            </Box>
          </Box>

        </Container>
      </section>
    </>
  );
};

export default ResourceList;
