import React from "react";
import { useStyles } from "../../../styles/global";
import { Container, Typography, Box, useMediaQuery} from "@material-ui/core";

const Intro = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section className={classes.intro}>
        <Container>
          <div className={classes.introContent}>
            <div className={classes.introContentInner}>
                <div className={classes.introDescription}>
                  <Typography variant="body1">
                    Thank you for believing in music! The online Believe in Music event features livestreamed content, interactive brand pages to connect buyers and sellers, and targeted education to attract a diverse audience.
                  </Typography>
                </div>
              </div>
            </div>
        </Container>
      </section>
    </>
  );
};

export default Intro;
