import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./../styles/global";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import TwitterIcon2 from "@material-ui/icons/Twitter";
import FacebookIcon2 from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Links } from "../data/links";

function Copyright() {
  const classes = useStyles();
  return (
    <div className={classes.footerCopyright}>
      <div className={classes.footerCopyrightImg}>
        <img
          src="https://static-believeinmusic.s3-us-west-2.amazonaws.com/believe-in-music-emblem-3.png"
          alt=""
        />
      </div>
      <div className={classes.footerCopyrightInfo}>
        {"Copyright © "}
        <Link color="inherit" href="https://www.namm.org" target="_blank">
          NAMM
        </Link>{" "}
        {new Date().getFullYear()}
      </div>
    </div>
  );
}

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <div className={classes.footerSplit}>
            <div className={classes.footerColumn1}>
              <Typography component={"div"} variant="body1">
                <Copyright />
              </Typography>
            </div>
            <div className={classes.footerColumn2}>
              <div className={classes.footerSocialIcons}>
                <a
                  href={Links.facebook}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FacebookIcon2 className={classes.socialIcon} />
                </a>
                <a
                  href={Links.twitter}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TwitterIcon2 className={classes.socialIcon} />
                </a>
                <a
                  href={Links.instagram}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <InstagramIcon className={classes.socialIcon} />
                </a>
              </div>
            </div>
            <div className={classes.footerColumn3}>
              <Typography
                component={"div"}
                variant="body1"
                align="right"
                className={classes.footerText}
              >
                <div className={classes.footerPolicyLinks}>
                  <Link color="inherit" variant="body2" href="/privacy">
                    Privacy Notice
                  </Link>{" "}
                  <Link color="inherit" variant="body2" href="/terms">
                    Terms of Service
                  </Link>
                  <Link color="inherit" variant="body2" href="/code-of-conduct">
                    Code of Conduct
                  </Link>
                </div>
              </Typography>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
}
