import React from "react";
import { IAppContextInterface, withAppContext } from "../../AppContext";
import marked from "marked";
import { Typography } from "@material-ui/core";
import { useStyles } from "../../styles/global";
import Container from "@material-ui/core/Container";

const Terms = ({ appContext }: { appContext: IAppContextInterface }) => {
  const classes = useStyles();
  const { terms } = appContext.pageContent;
  const markdownViewer = (markdown: string) => {
    let html = marked(markdown);
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>;
  };

  console.log(terms);

  return (
    <section>
      <Container maxWidth="md">
        <div className={classes.stepHeight}>
          <div className={classes.root}>
            <div className={classes.containerConstrain}>
              <h2>{terms.attributes.title}</h2>
              <Typography className={classes.formBodyP}>
                {markdownViewer(terms.attributes.body.value)}
              </Typography>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default withAppContext(Terms as React.FunctionComponent);
