import React from "react";
import { useStyles } from "../../../styles/global";
import { Container, Typography, Grid, Box, useMediaQuery, Link } from "@material-ui/core";

const CardWide = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section id="download" className={classes.cardWide}>
        <Container className={classes.container}>
          <div className={classes.cardWideSectionTitle}>
            <Typography variant="h3">
              Download The App
            </Typography>
          </div>
          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.redTest}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <div className={classes.cardWideInner}>
                <div className={classes.cardWideImg}>
                  <img src="https://www.namm.org/sites/www.namm.org/files_public/resources/downloadapp.jpg" alt="Download The App" title="Download The App" />
                </div>
                <div className={classes.cardWideInfo}>
                  <div className={classes.cardWideTitle}>
                    <Typography variant="h2">
                       Take Believe in Music on the go, with an optimized mobile app
                    </Typography>
                  </div>
                  <div className={classes.cardWideCtas}>
                    <div className={classes.cardWideCta}>
                      <div className={`${classes.aCta} ${classes.halfVruMarginTop}`}>
                        <Link href="https://apps.apple.com/us/app/namms-believe-in-music/id1545223644" rel="nofollow" target="_blank" underline="none">
                          App Store
                        </Link>
                      </div>
                    </div>
                    <div className={classes.cardWideCta}>
                      <div className={`${classes.aCta} ${classes.halfVruMarginTop}`}>
                        <Link href="https://play.google.com/store/apps/details?id=com.swapcard.apps.android.believeinmusic" rel="nofollow" target="_blank" underline="none">
                          Google Play
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>

        </Container>
      </section>
    </>
  );
};

export default CardWide;
