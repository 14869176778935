import React from "react";
import { Typography, Box } from "@material-ui/core";

type SwapCardSessionsProps = {
  iframe: string;
};

const SwapCardSessions = (props: SwapCardSessionsProps) => {
  // const iframe = () => {
  //   return {
  //     _html: props.iframe
  //   }
  // }

  function iframe() {
    return {
      __html: '<iframe src="https://www.believeinmusic.tv/widget/event/believe-in-music-2022/plannings/RXZlbnRWaWV3XzI4Njc5Mw==?showActions=true" style="border: none; margin: 0px; width: 100%; display: block; height: calc(100vh - 100px);"></iframe>',
    };
  }

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">
          Events may take a moment to load. Thank you for your patience.
        </Typography>
      </Box>

      <div dangerouslySetInnerHTML={iframe()} />
    </div>
  );
};

export default SwapCardSessions;
