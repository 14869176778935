import React from "react";
import Hero from "./Sections/Hero";
import { useStyles } from "../../styles/global";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import Intro from "../../components/Intro";
import EventPilars from "../../components/EventPilars";
import ScheduleAtAGlance from "../../components/ScheduleAtAGlance";
import BmTv from "../../components/BmTv";
import InteractiveBrandPages from "../../components/InteractiveBrandPages";
import Education from "../../components/Education";
import WhoShouldAttend from "../../components/WhoShouldAttend";
import PromotedEvents from "../../components/PromotedEvents";
// import Subscribe from "./Sections/Subscribe";
// import IconSection from './Sections/IconSection';
// import Faq from "./Sections/Faq";
// import SocialShare from './Sections/SocialShare';
// import FeaturedVideo from './Sections/FeaturedVideo';
// import SaveTheDate from './Sections/SaveTheDate';
// import { JumpMenu } from './Sections/JumpMenu';
// import { Audiences } from './Sections/Audiences';
// import {ArticleSection} from './Sections/ArticleSection';
// import MarketPlaceSection from './Sections/MarketPlaceSection';
// import Tv from './Sections/Tv';
// import TrainingSection from './Sections/TrainingSection';
// import ImportantDates from './Sections/ImportantDates';
// import PromotedEvents from './Sections/PromotedEvents';

function ScrollTopOfPage(props: any) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.zoomRoot}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTopOfPage.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const LandingPage = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <main>
        {/* Adding a padding allow content in ContentPreview.tsx to not be covered by contsent message */}
        <Hero />
        <Intro />
        <EventPilars />
        {/* <ScheduleAtAGlance /> */}
        <BmTv />
        <Education />
        <PromotedEvents />
        <div>
          <InteractiveBrandPages />

          <WhoShouldAttend />
        </div>
        {/* <Subscribe /> */}
        {/* <FeaturedVideo />
        <SaveTheDate />
        <PromotedEvents />
        <Tv />
        <MarketPlaceSection />
        <ImportantDates />
        <ArticleSection />
        <JumpMenu />
        <Audiences />
        <SocialShare /> */}
        {/* <Faq /> */}
        <CookieConsent
          location="bottom"
          buttonText="I agree"
          cookieName="gdprCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={364}
        >
          This website uses cookies for the purposes of analytics,
          personalization, and to understand how you and other visitors use our
          site. By utilizing this website, you agree to our use of cookies{" "}
        </CookieConsent>
        <ScrollTopOfPage {...props}>
          <Fab color="default" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon htmlColor="#00afed" />
          </Fab>
        </ScrollTopOfPage>
      </main>
    </>
  );
};

export default LandingPage;
