import React from "react";
import { useStyles } from "../../../styles/global";
import { Container, Typography, Grid, Box, useMediaQuery, Link } from "@material-ui/core";

const LoginInfo = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)"); //TODO: Use variable from theme

  return (
    <>
      <section className={classes.support}>
        <Container className={classes.container}>

          <Box
            gridTemplateColumns={{
              xs: "repeat(6, 1fr)",
              md: "repeat(12, 1fr)",
            }}
            gridGap={{ xs: "18px", md: "36px" }}
            display={{ xs: "grid" }}
            className={classes.redTest}
          >
            <Box gridColumn={{ xs: "1/-1", md: "2/-2" }}>
              <Grid
                container
                alignItems="center"
                direction={largeScreen ? "row" : "column-reverse"}
              >
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textSection}>
                    <div className={classes.textSectionBlock}>
                      <div className={classes.textSectionTitle}>
                        <Typography variant="h2">
                          Log in Instructions
                        </Typography>
                      </div>
                      <div className={classes.textSectionBody}>
                        <Typography variant="body1">
                          1. Go to: <Link href="https://www.believeinmusic.tv/event/believe-in-music-2022" target="_blank">https://www.believeinmusic.tv/event/believe-in-music-2022</Link>
                        </Typography>
                        <Typography variant="body1">
                          2. Select CLICK HERE TO LOG IN WITH YOUR NAMM ACCOUNT.
                        </Typography>
                        <Typography variant="body1">
                          <Link href="mailto:registration@namm.org">Email registration@namm.org</Link> if you have issues logging in.
                        </Typography>
                      </div>
                      <div className={`${classes.aCta} ${classes.oneVruMarginTop}`}>
                        <Link
                          href="https://www.believeinmusic.tv/event/believe-in-music-2022"
                          target="_blank"
                          underline="none"
                        >
                          Log in Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div
                    className={`${classes.twoColumnImg} ${classes.twoColumnImgReverse}`}
                  >
                    <img
                      src="https://www.namm.org/sites/www.namm.org/files_public/resources/login-screen-believe-in-music.png"
                      alt="Log in screen"
                      title="Log in screen"
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default LoginInfo;
