import React from "react";
import { useStyles } from "../styles/global";
import { Container, Typography, Grid, useMediaQuery } from "@material-ui/core";

const WhoShouldAttend = () => {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width:960px)");

  return (
    <>
      <section id="brand-page" className={classes.whoShouldAttend}>
        <Container>
          <div className={classes.sectionIntroCentered}>
            <div className={classes.sectionIntroCenteredTitle}>
              <Typography variant="h2">Who Should Attend</Typography>
            </div>
          </div>

          <Grid container direction={largeScreen ? "row" : "column"}>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--artists.jpg"
                    alt="Artist & Music Enthusiasts"
                    title="Artist & Music Enthusiasts"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">
                    Artist & Music Enthusiasts
                  </Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    View exclusive artist performances and interviews, and enjoy
                    music & wellness sessions
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--educators.jpg"
                    alt="Educators"
                    title="Educators"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Educators</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Music education sessions to expand your network and
                    knowledge of the latest technologies
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend-exhibitors.jpg"
                    alt="Exhibitors"
                    title="Exhibitors"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Exhibitors</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Reach a world of buyers and influencers
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend-independent-retailers.jpg"
                    alt="Independent Retailers"
                    title="Independent Retailers"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Independent Retailers</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Connect with the industry and discover new trends with NAMM
                    U education.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--house-of-worship.jpg"
                    alt="House Of Worship"
                    title="House Of Worship"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">House Of Worship</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Take your worship production to the next level, with curated
                    education
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--live-event-production.jpg"
                    alt="Live Event Production"
                    title="Live Event Production"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Live Event Production</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    ESTA-curated programs and education
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--policy-and-nonprofit.jpg"
                    alt="Policy and Non-profit"
                    title="Policy and Non-profit"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Policy and Non-profit</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Learn from U.S. and international policy sessions, as well
                    as the Non-Profit Management Institute
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend-pro-audio.jpg"
                    alt="Pro Audio"
                    title="Pro Audio"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Pro Audio</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Cutting-edge TEC Tracks, AES and A3E sessions
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item={true} xs={12} md={4} className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.cardImg}>
                  <img
                    src="https://www.namm.org/sites/www.namm.org/files_public/resources/who-should-attend--students.jpg"
                    title="Students"
                    alt="Students"
                  />
                </div>
                <div className={classes.cardTitle}>
                  <Typography variant="h3">Students</Typography>
                </div>
                <div className={classes.cardInfo}>
                  <Typography variant="body1">
                    Grow your career and skillset through GenNext and NAMM Young
                    Professionals programming
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default WhoShouldAttend;
