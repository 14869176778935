import React from "react";
import { IAppContextInterface, withAppContext } from "../../AppContext";
import Container from "@material-ui/core/Container";
//import marked from 'marked';
import { useStyles } from "../../styles/global";
//import Faq from "../LandingPage/Sections/Faq";
//import Hero from "../LandingPage/Sections/Hero";

const Faqs = ({ appContext }: { appContext: IAppContextInterface }) => {
  const classes = useStyles();
  // const markdownViewer = (markdown: string) => {
  //   let html = marked(markdown);
  //   return(
  //     <span dangerouslySetInnerHTML={{__html: html}}></span>
  //     )
  //   }
  const html: any = appContext.pageContent.faqs.attributes.body.processed;

  return (
    <>
      <Container>
        <div className={classes.containerInner}>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </Container>
    </>
  );
};

export default withAppContext(Faqs as React.FunctionComponent);
